/*-------------------------
  Helper Classes
-------------------------*/

.bg-color-dark {
    background-color: var(--color-dark);
}

.bg-color-light {
    background-color: var(--color-light);
}

.bg-color-mercury {
    background-color: var(--color-mercury);
}

.bg-color-ship-gray {
    background-color: var(--color-ship-gray);
}

// Color
.color-gray-4 {
    color: var(--color-gray-4);
}

// Opacity
.opacity-50 {
    opacity: 0.5;
}

.shape-group-8 .shape-3 img {
    filter: brightness(3.5);
}

.shape-group-17 .shape-4 img {
    filter: brightness(3.5);
}

.shape-group-17 .shape-5 img {
    filter: brightness(3.5);
}

.position-rel {
    position: relative;
}

.title-right-line {
    position: absolute;
    top: -20px;
    margin-left: 20px;
    @media #{$sm-layout} {
        display: none;
    }
}

.title-right-line-btn {
    position: absolute;
    top: 15px;
    margin-left: 50px;
    filter: brightness(3.5);
    @media #{$sm-layout} {
        display: none;
    }
}

.motivation-line {
    position: relative;
    filter: brightness(3.5);

    img {
        position: absolute;
        right: 0;
        max-width: 1042px;
        min-width: 1042px;
        width: 1042px;
        height: auto;
        margin-top: 30px;
        @media #{$sm-layout} {
            display: none;
        }
    }
}

.hacker-method {
    position: relative;
    margin-top: 60px;
    padding-top: 60px;

    .contenedor-hacker {
        position: relative;
        left: 30px;
        @media #{$sm-layout} {
            left: 12px;
        }
    }

    ul {
        list-style: none;
        padding-left: 0;
    }

    li {
        Button {

            padding: 16px 52px;
            border-radius: 12px;
            color: var(--color-dark) !important;
            font-size: 18px;
            font-weight: 600;
            width: fit-content;
            position: absolute;
            border: none;
            @media #{$sm-layout} {
                position: unset;
                width: 100%;
                margin-top: 20px;
            }
        }
        
        img {
            position: absolute;
            @media #{$sm-layout} {
                display: none;
            }
        }

        &:first-child Button {
            background-color: var(--color-rose);
        }

        &:last-child Button {
            background-color: var(--color-rose);
        }

        &:not(:first-child):not(:last-child) Button {
            background-color: var(--color-primary);
        }

        &:nth-child(3) Button {
            background-color: var(--color-accent3) !important;
        }

        &:nth-child(1) Button {
            top: 0;
            left: 0;
            @media #{$lg-layout} {
                left: -2%;
            }
            @media #{$md-layout} {
                left: -5%;
            }

            img {
                top: 50px;
                left: 50%;

                @media #{$lg-layout} {
                    top: 23px;
                    left: 15%;
                }

                @media #{$md-layout} {
                    top: 5px;
                    left: 15%;
                }
            }
        }

        &:nth-child(2) Button {
            top: 0;
            right: 0;

            img {
                top: 50px;
                right: 50%;
            }
        }

        &:nth-child(3) Button {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            @media #{$lg-layout} {
                left: 46%;
                transform: none;
            }
            @media #{$md-layout} {
                left: 46%;
            }
            @media #{$sm-layout} {
                left: 46%;
                transform: none;
            }
        }

        &:nth-child(4) Button {
            bottom: -60px;
            left: 0;
            @media #{$lg-layout} {
                bottom: -60px;
                left: -2%;
            }
            @media #{$md-layout} {
                left: -5%;
            }
            
            img {
                bottom: 50px;
                left: 50%;
                @media #{$lg-layout} {
                    left: 15%;
                }
                @media #{$md-layout} {
                    left: 15%;
                }
            }
        }

        &:nth-child(5) Button {
            bottom: -60px;
            right: 0;

            img {
                bottom: 25px;
                right: 100%;
            }
        }

    }
}

.container-tooltip {
    .titleCTool {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 20px;

        img {
            width: 50px;
            height: auto;
        }

        h5 {
            margin-top: 0;
            margin-bottom: 0;
            margin-left: 10px;
            font-size: 28px;
            font-weight: 500;
            color: #222222 !important;
        }
    }

    p {
        font-size: 16px;
        color: #222222 !important;
        margin-bottom: 0;
    }
}

.tooltip {
    position: relative;
}

.tooltip {
    --bs-tooltip-zindex: 1080;
    --bs-tooltip-max-width: 400px;
    --bs-tooltip-padding-x: 30px;
    --bs-tooltip-padding-y: 30px;
    --bs-tooltip-margin: ;
    --bs-tooltip-font-size: 16px;
    --bs-tooltip-color: #222222;
    --bs-tooltip-bg: #ffffff;
    --bs-tooltip-border-radius: 6px;
    --bs-tooltip-opacity: 1;
    --bs-tooltip-arrow-width: 22px;
    --bs-tooltip-arrow-height: 16px;
    z-index: var(--bs-tooltip-zindex);
    display: block;
    padding: var(--bs-tooltip-arrow-height);
    margin: var(--bs-tooltip-margin);
    font-family: var(--font-secondary);
    font-family: var(--font-secondary);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: left;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    white-space: normal;
    word-spacing: normal;
    line-break: auto;
    font-size: var(--bs-tooltip-font-size);
    word-wrap: break-word;
    opacity: 1
}

.tooltip-inner {
    max-width: var(--bs-tooltip-max-width);
    padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
    color: #222222;
    text-align: left;
    background-color: #ffffff;
    border-radius: 6px;
}