/*-------------------------
   Brand
-------------------------*/
.brand-wrap-area {
    padding: 90px 0;
}

.brand-grid {
    padding: 10px 0;

    img {
        filter: grayscale(1);
        opacity: 0.3;
        transition: filter 0.8s, opacity 0.8s;
        margin: auto;
        display: block;
    }

    &:hover,
    &.active {
        img {
            opacity: 1;
            filter: grayscale(0);
        }
    }
}